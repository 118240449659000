<template>
  <div class="page">
    <h1>お支払い方法</h1>
    <div class="content">
      <h2>１． アプリへのサインイン</h2>
      <p>
        メールアドレスがひもづいたお手持ちのアカウント（現在はGoogleアカウントのみ）を使用して
        <a href="https://print.mihiraku.com/" target="_blank" rel="noopener noreferrer">アプリ</a>
        にサインインしてください。</p>
      <img class="guide" src="/image/page/service/print/payment/sign-in.png" alt="サインイン手順を示すスクリーンショット">
      <h2>２． お支払いメールの送信指示</h2>
      <p>画面右上にある ログイン中のユーザー名 を選択し、お求めのプランを選択してください。</p>
      <img class="guide" src="/image/page/service/print/payment/request.png" alt="プラン選択手順を示すスクリーンショット">
      <h2>３． お支払い</h2>
      <p>
        お支払いメールは、サインイン中のアカウントにひもづいたメールアドレス宛にお送りいたします。
        お支払いの詳細手順は、メールの内容をご参照ください。
        お支払いが完了してから２４時間以内に、お支払いいただいたプランへと移行いたします。
      </p>
      <p>
        お問い合わせは、下記のメールアドレスまでお願いいたします。
        <br>
        <img class="mail" src="/image/my/mail.png" alt="メールアドレス"><br>
      </p>
    </div>
    <p class="next-comment">
      良心的な「
      <router-link to="/service/print/policy/price/">価格</router-link>
      」で 目一杯 使うことができます<br>
      まずは <b>無料</b>で 「
      <a href="https://print.mihiraku.com/" target="_blank" rel="noopener noreferrer">お試し</a>
      」ください<br>
    </p>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
